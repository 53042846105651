var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{staticClass:"inside-admin"},[_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":_vm.photoUrl,"alt":""}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("管理员头像")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.photoUrl),expression:"photoUrl"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"请输入头像链接"},domProps:{"value":(_vm.photoUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.photoUrl=$event.target.value}}})]),_c('InputGroup',{attrs:{"current-value":_vm.name,"validates":[{
      rule: 'isNotEmpty',
      errMsg: '管理员帐号不能为空'
    }],"label":"管理员帐号","placeholder":"请输入管理员帐号"},on:{"get-info":_vm.getAccountInfo}}),_c('InputGroup',{attrs:{"current-value":_vm.password,"validates":[
      {
        rule: 'minLength:6',
        errMsg: '密码长度不少于6个'
      }
    ],"maxlength":16,"label":"管理员密码","placeholder":"请输入管理员密码"},on:{"get-info":_vm.getPasswordInfo}}),_c('InputGroup',{attrs:{"current-value":_vm.email,"validates":[
      {
        rule: 'isNotEmpty',
        errMsg: '管理员邮箱不能为空'
      },
      {
        rule: 'isEmail',
        errMsg: '邮箱格式不正确'
      }
    ],"label":"管理员邮箱","placeholder":"请输入管理员邮箱"},on:{"get-info":_vm.getEmailInfo}}),_c('InputGroup',{attrs:{"current-value":_vm.job,"label":"职业","placeholder":"输入职业则会显示在首页"},on:{"get-info":_vm.getJobInfo}}),_c('InputGroup',{attrs:{"current-value":_vm.intro,"label":"简介","placeholder":"输入简介则会显示在首页"},on:{"get-info":_vm.getIntroInfo}}),_c('ArticleTypes',{attrs:{"data":_vm.articleTypes},on:{"update:data":function($event){_vm.articleTypes=$event}}}),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-info",attrs:{"disabled":!_vm.canSubmit},on:{"click":_vm.renewBlog}},[_vm._v("Submit")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }