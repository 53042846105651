var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('uiv-modal',{attrs:{"title":"登录","cancel-text":"取消","ok-text":"确定","size":"sm"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('InputGroup',{attrs:{"validates":[{
      rule: 'isNotEmpty',
      errMsg: '用户名不能为空'
    }],"maxlength":12,"current-value":_vm.username,"label":"用户名","placeholder":"请输入用户名","name":"username"},on:{"get-info":_vm.getUsernameInfo,"on-enter":_vm.submit}}),_c('InputGroup',{attrs:{"current-value":_vm.password,"validates":[
      {
        rule: 'isNotEmpty',
        errMsg: '密码不能为空'
      }, 
      {
        rule: 'minLength:6',
        errMsg: '密码长度不少于6个'
      }
    ],"maxlength":16,"label":"密码","name":"password","type":"password","placeholder":"请输入密码"},on:{"get-info":_vm.getPasswordInfo,"on-enter":_vm.submit}}),_c('InputCaptcha',{attrs:{"current-value":_vm.captchaVal},on:{"get-info":_vm.getCaptchaInfo,"on-enter":_vm.submit,"refresh":_vm.getCaptcha}}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('uiv-btn',{on:{"click":function($event){_vm.value=false}}},[_vm._v("取消")]),_c('uiv-btn',{attrs:{"disabled":!_vm.canSubmit || _vm.isLoading,"type":"primary"},on:{"click":_vm.submit}},[_vm._v("\n      确定\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }