var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('uiv-modal',{attrs:{"title":"修改用户","cancel-text":"取消","ok-text":"确定","size":"sm"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('InputGroup',{attrs:{"current-value":_vm.password,"validates":[
      {
        rule: 'minLength:6',
        errMsg: '密码长度不少于6个'
      }
    ],"maxlength":16,"label":"密码","name":"password","type":"password","placeholder":"请输入密码"},on:{"get-info":_vm.getPasswordInfo}}),_c('InputGroup',{attrs:{"current-value":_vm.email,"validates":[
      {
        rule: 'isNotEmpty',
        errMsg: '邮箱不能为空'
      }, 
      {
        rule: 'isEmail',
        errMsg: '请输入正确的邮箱'
      }
    ],"maxlength":16,"label":"邮箱","name":"email","placeholder":"请输入邮箱"},on:{"get-info":_vm.getEmailInfo}}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('uiv-btn',{on:{"click":function($event){_vm.value=false}}},[_vm._v("取消")]),_c('uiv-btn',{attrs:{"disabled":!_vm.canSubmit,"type":"primary"},on:{"click":_vm.submit}},[_vm._v("\n      确定\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }